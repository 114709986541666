html {
  height: 100%;
  width: 100%;
  margin: 0;
  viewport-fit: cover;
  overflow: hidden;
  overscroll-behavior-y: contain; /* Disables pull-to-refresh */
  overscroll-behavior-block:contain ;
}

#root {
  height: 100%;
}

:root {
  --inner-height: 100vh; /* Fallback value */
}


.app {
  font-family: Arial, sans-serif;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: first st;
}

.content {
  margin-top: 0px;
  flex: 1;
  padding: 2px;
  touch-action: pan-y;
  /*background-image: url("https://png.pngtree.com/background/20210710/original/pngtree-car-loan-poster-background-material-picture-image_1046817.jpg");*/
  background-color: #ffec99;
  background-size: cover;
  background-repeat: no-repeat;
  
}

body {
  overflow: hidden; /* Disable scrollbars */
  height: 100%; /* Ensure full height */
  margin: 0;
  padding: 0;
  touch-action:pan-y;
  overscroll-behavior-y: contain; /* Disables pull-to-refresh */
  overscroll-behavior-block:contain ;
}

* {
  box-sizing: border-box;
}

.fullscreen-button {
  background: none;
  border: none;
  color: #ffffff; /* Blue color for the text */
  font-size: 16px; /* Adjust the font size as needed */
  cursor: pointer;
  padding: 10px 20px; /* Padding for better click area */
  transition: color 0.3s ease, background-color 0.3s ease; /* Smooth transition */
}

.fullscreen-button:hover,
.fullscreen-button:focus {
  color: #14191e; /* Darker blue on hover/focus */
  background-color: rgba(
    255,
    255,
    255,
    0
  ); /* Light blue background on hover/focus */
  outline: none; /* Remove default outline */
}

.fullscreen-button:active {
  color: #ffffff; /* Even darker blue when active */
  background-color: rgba(
    0,
    123,
    255,
    0.2
  ); /* Slightly darker background when active */
}
