/* src/components/MobileFooter.css */

.footer {
  background-color: #333;
  color: #fff;
  padding: 20px;
  text-align: center;
  flex-shrink: 0;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-nav a {
  color: #fff;
  text-decoration: none;
  margin: 5px;
}

.footer-nav a:hover {
  text-decoration: underline;
}

/* Style for the circular home button */
.home-button {
  display: inline-block;
  width: 60px;
  height: 60px;
  border-radius: 50%; /* Makes it a circle */
  background-color: #007bff; /* Blue background color */
  color: #fff; /* Text color */
  font-size: 24px;
  text-align: center;
  line-height: 60px; /* Vertically center the text */
  box-shadow: 0 3px 6px rgba(0, 123, 255, 0.3); /* Box shadow for depth */
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}
