.banner {
  width: 100%;
}

.nav_other_categories{
  width: 100%;
  height: 100%;
  display: flex;
 
  /*background: #fff;*/
  flex-wrap: wrap;
  
}

.nav_other_categories .cat_icon_bg{
  width: 20vw;
  height: 20vw;
  margin-top: 6px;
  background-color: #0062a4;
  display: inline-block;
  border-radius: 100%;
}


.cat_text{
  min-height: 3rem;
  display: table;
  width: 20vw !important;
  border-radius: 10px;
  margin-top: 2px;
  font-weight: bold;
  text-decoration: none;
  color: white;
  background-color: rgb(119, 0, 0);
}

.nav_other_categories div{
  width: 24vw;
}

.nav_other_categories div img{
  width: 20vw;
}


.nav_other_categories div a{
  text-align: center;
  padding: 8px;
  display: block;
  text-decoration: none;
}