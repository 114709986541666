/* EmptyDivContainer.css */

.container {
  height: 30vh; /* Set container height to 100% of viewport height */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0; /* Optional: Set background color */
}

.empty-div {
  width: 100%; /* Set width to 100% of parent container */
  height: 100%; /* Set height to 100% of parent container */
  border: 2px dashed #007bff; /* Optional: Add border for visualization */
}
