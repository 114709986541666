.car_fee_card {
  width: 100%;
  max-width: 100%;
  height: 4rem;
  display: flex;
  justify-content: space-between;
  background-color: #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 2px;
  border-radius: 8px;
  box-sizing: border-box;
  margin: 0 auto; /* Centers the card horizontally */
}

.car_fee_card_col {
  flex-basis: 25vw;
}

.car_fee_card img {
  max-width: 25vw;
  width: 25vw;
  height: 15vh;
  object-fit: cover;
  border-radius: 10%;
  margin: 5px;
}

@media (max-width: 768px) {
  .car_fee_card {
    padding: 2px;
  }
}

.price-tag {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100vw; /* Adjust width as needed */
  padding: 15px;
  border: 1px solid #ccc;
  background-color: #f0f0f0;
  border-radius: 8px;
  font-family: Arial, sans-serif;
}

.price-info {
  flex: 1; /* Take up remaining space */
  align-items: center;
}

.source,
.destination {
  font-size: 12pt;
  font-weight: bold;
}

.price {
  font-size: 1.5rem;
  color: #007bff; /* Blue color for price */
}
.price button {
  margin-left: 1vw;
  padding: 8px 20px 8px 20px;
  border: none;
  border-radius: 5px;
  background-color: #4caf50; /* Green */
  color: white;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.car_fee_container .car_order {
  vertical-align: top;
  align-items: center;
  margin-right: 5px;
  margin-left: 5px;
  overflow-y: hidden;
  touch-action: pan-y;
  flex: 1;
}

.car_fee_container .title {
  display: flex;
  justify-content: space-between;
  color: white;
  border-radius: 10px;
  background-color: black;
  opacity: 0.75;
  background-blend-mode: multiply;
  align-items:center;
  height: 4rem;
}

.car_fee_container .title .search {
  cursor: pointer;
  background-color: white;
  opacity: 1;
  border-radius: 50%;
  padding: 0.5rem;
  margin: 0.5rem;
  width:3 rem;
  height: 3rem;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

.car_fee_container .title .reverse {
  cursor: pointer;
  opacity: 1;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  padding: 10px;
  margin: 10px;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  border-radius: "50%";
  background-color: #fff;
}


#caption h1 {
  color: white;
  margin-left: 0.5em;
}

.car_fee_container .car_fee_group {
  overflow: hidden;
  background-color: white;
  border-radius: 5px;
}

.car_fee_container .header_image {
  width: 98vw;
  height: 15vh;
  object-fit: cover;
}

.stroke-icon {
  font-size: 2rem; /* Adjust icon size */
  color: red; /* Icon color */
  -webkit-text-stroke: 2px black; /* Stroke effect (WebKit/Blink browsers) */
  text-stroke: 2px black; /* Stroke effect (standard syntax) */
}

.order-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 300px;
  padding: 15px;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  background-color: #ff5733;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  text-decoration: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

/*.search-box {
  width: 100%;
  max-width: 600px;
  height: 48px;
  padding: 0 16px;
  font-size: 16px;
  border: 2px solid #ccc;
  border-radius: 24px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s, box-shadow 0.3s;
}*/

.search-box {
  display: flex;
  align-items: center; /* Aligns icon and text vertically */
  width: 100%; /* Adjust width as needed */
  height: 48px;
  border: 1px solid #ccc; /* Adjust border style as needed */
  background-color: #fff;
  border-radius: 5px; /* Adjust border radius as needed */
}

.search-input {
  width: calc(100% - 30px); /* Adjust width to accommodate icon */
  padding: 10px;
  font-size: 16px;
  height: 46px;
  border-radius: 5px;
  border: none; /* Remove default input border */
  outline: none; /* Remove default outline on focus */
}

.search-input::placeholder {
  color: #999;
}

.location-icon {
  font-size: 20px; /* Adjust icon size as needed */
  margin-left: 10px;
  color: #ccc; /* Adjust icon color as needed */
}