/* FooterButton.css */

.footer-button-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  /*background: linear-gradient(180deg, #ffffff, #ffbdbd);*/
  color: #fff;
  text-align: center;
  padding: 10px;
  /*height: 5vh; /* Fixed height for the footer */
}

.footer-button-container .dock {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4rem;
  background-color: #002842;
  z-index: -1;
}

.footer-button {
  padding: 10px 10px;
  margin: 5px;
  font-size: 2.5rem;
  font-weight: bold;
  background-color: #002842;
  color: #fff;
  border-width: 5px;
  border-color: #fff;
  border-radius: 50%;
  cursor: pointer;
  z-index: 9;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}


.footer-button:hover {
  background: linear-gradient(45deg, #3498db, #2ecc71, #e74c3c, #f39c12);
  background-size: 200% 200%;
  animation: spin 2s linear infinite;
  outline: none;
  -webkit-animation: spin 2s infinite linear;
}

@-webkit-keyframes spin {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

@keyframes spin {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}
