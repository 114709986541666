/* src/components/CategoryIconList.css */

.category-icon-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.category-icon-list button {
  padding: 0px 0px;
  margin: 5px;
  width: 20vw;
  height: 20vw;
  font-size: 0.8rem;
  font-weight: bold;
  background-color: #0062a4;
  color: #fff;
  border-width: 5px;
  border-color: #fff;
  border-radius: 50%;
  cursor: pointer;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

.category-item {
  width: 25%; /* Set width to fit three items per row */
  margin-bottom:0px;
  text-align: center;
}

.category-icon {
  width: 80px; /* Adjust icon size as needed */
  height: 80px;
  object-fit: contain;
  border-radius: 50%;
  /*margin-bottom: 10px;*/
  margin:0;

}

.category-name {
  font-size: 1rem;
  font-weight: bold;
  color: #ffffff;
}

.category-name-container {
  width: 20vw;
  background-color: rgb(119, 0, 0);
  border-radius: 10px;
  padding: 5px;
  min-height: 3rem;
  text-align: center; 
  margin-left: 0.75rem;
}

.category-item-link {
  text-decoration: none;
}
