/* src/components/Header.css */

.header {
  display: flex;
  justify-content: center;
  vertical-align: middle;
  align-items: center;
  align-content: center;
  text-align: center;
  background-color: #002842;
  color: #ffffff;
  padding: 0rem;
  height: 4rem;
}

.logo {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
}

.nav-links a {
  color: #fff;
  text-decoration: none;
  margin-left: 10px;
}

.nav-links a:hover {
  text-decoration: underline;
}

.flag-icon {
  
  padding-left: 0.1rem;
}

@media (max-width: 768px) {

  .flag-icon {
    width: 2rem;
    padding-left: 0.1rem;
  }

  .logo {
    font-size: 1.3rem;
    font-weight: bold;
    text-align: center;
  }

}